import './App.css';

import TopNav from './components/TopNav';
import BottomNav from './components/BottomNav.jsx';
import { lazy, Suspense } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { GlowvaRoutes } from './config/route.jsx';
import Copyright from './components/Copyright.jsx';
import AppleAppSiteAssociation from './components/AppleAppSiteAssociation.jsx'

import ChatbotDot from './components/chat/ChatbotDot.jsx';

// lazy load
const Home = lazy(() => import('./containers/Home.jsx'));
const Resources = lazy(() => import('./containers/Resources.jsx'));
const Community = lazy(() => import('./containers/Community.jsx'));
const Contact = lazy(() => import('./containers/Contact.jsx'));
const FAQ = lazy(() => import('./containers/FAQ.jsx'));
const Error404 = lazy(() => import('./containers/Error404.jsx'));
const ChatPage = lazy(() => import('./containers/ChatPage.jsx'));
const SignIn = lazy(() => import('./containers/account/SignIn.jsx'));
const PasswordReset = lazy(() => import('./containers/account/PasswordReset.jsx'));
const AccountCreate = lazy(() => import('./containers/account/AccountCreate.jsx'));
const NewEmailVerification = lazy(() => import('./containers/account/NewEmailVerification.jsx'));
const WaitingForVerification = lazy(() => import('./containers/account/WaitingForVerification.jsx'));
const CompleteSignIn = lazy(() => import('./containers/account/CompleteSignIn.jsx'));

function App() {
  const location = useLocation();
  const pathname = location.pathname;

  if (pathname === '/.well-known/app-app-site-association') {
    return <AppleAppSiteAssociation />;
  }

  return (
    <>
      {/* className="container-fluid" */}
      <div className="container-fluid px-0 mx-0">
        <div className="row">
          <div className="col-12">
            <TopNav></TopNav>
          </div>
        </div>

        {/* navigation router */}
        <div className="row">
          {/* <div className="col-12"> */}
          {/* Lazy load */}
          <Suspense fallback={<div>Loading...</div>}>
            {/* Register routes */}
            <Routes>
              <Route path={GlowvaRoutes.HOME} element={<Home />} />
              <Route path={GlowvaRoutes.RESOURCES} element={<Resources />} />
              <Route path={GlowvaRoutes.COMMUNITY} element={<Community />} />
              <Route path={GlowvaRoutes.CONTACT} element={<Contact />} />
              <Route path={GlowvaRoutes.FAQ} element={<FAQ />} />
              <Route path={GlowvaRoutes.CHAT} element={<ChatPage />} />
              {/* log in related */}
              <Route path={GlowvaRoutes.SIGN_IN} element={<SignIn />} />
              <Route path={GlowvaRoutes.PASSWORD_RESET} element={<PasswordReset />} />
              <Route path={GlowvaRoutes.REGISTER1} element={<NewEmailVerification />} />
              <Route path={GlowvaRoutes.REGISTER2} element={<WaitingForVerification />} />
              <Route path={GlowvaRoutes.REGISTER3} element={<AccountCreate />} />
              <Route path="/complete-signin" element={<CompleteSignIn />} />
              {/* Redirect */}
              <Route path="/" element={<Navigate to={GlowvaRoutes.HOME} />} />
              <Route path="*" element={<Error404 />} />
            </Routes>
          </Suspense>
          {/* </div> */}
        </div>
        {/* end navigation router */}

        {/* <ChatbotDot></ChatbotDot> */}
        {pathname !== GlowvaRoutes.CHAT && <ChatbotDot />}

        <BottomNav></BottomNav>
        <Copyright></Copyright>
      </div>
    </>
  );
}

export default App;
