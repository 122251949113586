// import { React, useState } from 'react';
import '../../css/Chatbot.css';
import { useNavigate } from 'react-router-dom';
import { GlowvaRoutes } from '../../config/route.jsx';

function ChatBotDot() {

    const navigate = useNavigate();
    const handleClick = () => {
        navigate(GlowvaRoutes.CHAT);
    };

    return (
        <>
            <div className="chatbot-container text-center">
                <div className="" style={{fontSize: "var(--glowva-font-size-xxs)"}}>Glowva AI</div>
                <div className="" style={{fontSize: "var(--glowva-font-size-xxxs)"}}>Your Parenting Copilot</div>
                <div className="chatbot-dot mx-auto mt-1" onClick={handleClick} style={{ backgroundImage: `url(/chatbot_icon.svg)` }}></div>
            </div>
        </>
    );
}

export default ChatBotDot;