import { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { Navbar, Nav, Container } from 'react-bootstrap';
import { GlowvaRoutes } from '../config/route.jsx';

import Logo from '../assets/images/brands/logo.svg';

function TopNav() {
    // styles
    const navLinkDivCss = `nav-link nav-top-item`;
    const navLinkMargins = `mx-3`;

    const navigate = useNavigate();
    const location = useLocation();

    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    const [scrollToSubscription, setScrollToSubscription] = useState(false);

    const goToSubscription = () => {
        // Get the subscription field ref from the Home component
        const subscriptionFieldRef = document.getElementById("subscription-field-section");
        if (subscriptionFieldRef) {
            subscriptionFieldRef.scrollIntoView({ behavior: "smooth" });
            setScrollToSubscription(false);
        }
    }

    const delayGoToSubscription = () => {
        if (window.innerWidth < 991) { // mobile devices
            setExpanded(false); // collapse the nav bar
            setTimeout(() => {
                goToSubscription();
            }, 200); // delay the scroll to allow the nav bar to collapse
        } else {
            goToSubscription();
        }
    }

    const handleJoinUsClick = (event) => {
        event.stopPropagation();
        setScrollToSubscription(true);
        setExpanded(false);

        // Check if we're not already on the /Home page
        if (location.pathname !== GlowvaRoutes.HOME) {
            navigate(GlowvaRoutes.HOME);
            // then useEffect will be triggered
        } else {
            delayGoToSubscription();
        }
        // navigate(GlowvaRoutes.SIGN_IN);
    }

    useEffect(() => {
        if (location.pathname === GlowvaRoutes.HOME && scrollToSubscription) {
            delayGoToSubscription();
        }
    },);

    const handleLogoClick = () => {
        navigate(GlowvaRoutes.HOME);
    }

    return (
        <>
            <div className="row glowva-text-h5 pe-0 d-flex">
                <div className="col text-center d-block d-lg-none" style={{ position: "absolute", top: "1.5%" }}>
                    <img src={Logo} alt="glowva logo" className="nav-bar-logo ps-2" onClick={handleLogoClick} />
                </div>

                <Navbar expand="lg" expanded={expanded} onToggle={handleToggle} collapseOnSelect className="navbar-container">
                    <Container fluid>
                        <Navbar.Brand className="d-none d-lg-block mx-3">
                            <NavLink to={GlowvaRoutes.HOME} className="nav-link">
                                <img src={Logo} alt="glowva logo" className="nav-bar-logo ps-2" />
                            </NavLink>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="ms-auto align-items-center">
                                <NavLink to={GlowvaRoutes.HOME} className={`${navLinkDivCss} ${navLinkMargins}`} onClick={() => setExpanded(false)}>Home</NavLink>
                                <NavLink to={GlowvaRoutes.RESOURCES} className={`${navLinkDivCss} ${navLinkMargins}`} onClick={() => setExpanded(false)}>Resources</NavLink>
                                <NavLink to={GlowvaRoutes.COMMUNITY} className={`${navLinkDivCss} ${navLinkMargins}`} onClick={() => setExpanded(false)}>Community</NavLink>
                                <NavLink to={GlowvaRoutes.FAQ} className={`${navLinkDivCss} ${navLinkMargins}`} onClick={() => setExpanded(false)}>FAQ</NavLink>
                                <button type="button" className={`btn-join-us ${navLinkMargins}`} onClick={handleJoinUsClick}>
                                    <span className="nav-top-item btn-text">Join us</span>
                                </button>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

            </div>
        </>
    );
}

export default TopNav;

// // ------- original nav bar bak --------------
// <Navbar expand="lg" expanded={expanded} onToggle={handleToggle} collapseOnSelect className="navbar-container">
//                     <Container fluid>
//                         <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//                         <Navbar.Collapse id="responsive-navbar-nav">

//                             <Nav className="ms-4 me-0 px-0">
//                                 <div className="d-flex align-items-center col-2">
//                                     <NavLink to={GlowvaRoutes.HOME} className="nav-link">
//                                         <img src={Logo} alt="glowva logo" className="d-none d-lg-block nav-bar-logo ps-2" />
//                                     </NavLink>
//                                 </div>

//                                 <div className="col-6"></div>
//                                 {/* space between logo and nav bar */}

//                                 <div className={`${navLinkDivCss}`}>
//                                     <NavLink to={GlowvaRoutes.HOME} className="nav-link nav-top-item" onClick={() => setExpanded(false)}>Home</NavLink>
//                                 </div>
//                                 <div className={`${navLinkDivCss}`}>
//                                     <NavLink to={GlowvaRoutes.RESOURCES} className="nav-link nav-top-item" onClick={() => setExpanded(false)}>Resources</NavLink>
//                                 </div>
//                                 <div className={`${navLinkDivCss}`}>
//                                     <NavLink to={GlowvaRoutes.COMMUNITY} className="nav-link nav-top-item" onClick={() => setExpanded(false)}>Community</NavLink>
//                                 </div>
//                                 {/* <div className={`${navLinkDivCss}`}>
//                                     <NavLink to={GlowvaRoutes.CONTACT} className="nav-link nav-top-item" onClick={() => setExpanded(false)}>Contact</NavLink>
//                                 </div> */}
//                                 <div className={`${navLinkDivCss}`}>
//                                     <NavLink to={GlowvaRoutes.FAQ} className="nav-link nav-top-item" onClick={() => setExpanded(false)}>FAQ</NavLink>
//                                 </div>
//                                 <div className={`${navLinkDivCss} text-end pe-0`}>
//                                     <button type="button" className={`text-nowrap btn-join-us`} onClick={handleJoinUsClick}>
//                                         <span className="nav-top-item btn-text">Join us</span>
//                                     </button>
//                                 </div>
//                             </Nav>


//                         </Navbar.Collapse>
//                     </Container>
//                 </Navbar>